import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Space, Tabs } from 'antd';

import useForm, { FormContext } from '../../hooks/useForm';
import SellersList from './SellersList';
import ReteailerPosesList from './ReteailerPosesList';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';
import useDictionaries from '../../hooks/useDictionaries';
import { getSellerCompanies } from '../../services/taxonomies/companies';
import RetailerTvmList from './RetailerTvmList';

const dictionaries = {
  companies: getSellerCompanies,
};

const EditRetailerForm = ({ retailer, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        address: yup.string().required(t('errors.notEmpty')),
        name: yup.string().required(t('errors.notEmpty')),
        code: yup.string().required(t('errors.notEmpty')),
        company: yup.number().required(t('errors.mustSelected')),
      }),
    [t],
  );

  const form = useForm({
    initialValue: retailer,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const [{ companies }] = useDictionaries(dictionaries);

  const { value, handleSubmit, input, setFormValue, select } = form;
  const [isStocksEnabledString] = useState(window._env_.STOCKS_ENABLE);
  const isStocksEnabled = isStocksEnabledString === 'true';

  useEffect(() => {
    setFormValue(retailer);
  }, [setFormValue, retailer]);

  const { deleted } = value;

  return (
    <>
      <FormContext.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <PageTitle
            title={retailer.name || t('entity.taxonomy.retailer._singular')}
          />

          <FormWrapper>
            <FormWrapper.Single>
              <FormWrapper.Input
                label={t('entity.taxonomy.retailer.code')}
                props={{ ...input('code'), disabled: deleted }}
              />

              <FormWrapper.Input
                label={t('entity.taxonomy.retailer.name')}
                props={{ ...input('name'), disabled: deleted }}
              />

              <FormWrapper.Input
                label={t('entity.taxonomy.retailer.address')}
                props={{ ...input('address'), disabled: deleted }}
              />

              <FormWrapper.Select
                label={t('entity.taxonomy.poses.company')}
                props={{
                  options: companies.content,
                  loading: companies.loading,
                  ...select('company'),
                }}
              />

              <Space style={{ paddingTop: '1rem' }}>
                {!deleted && <SaveButton />}
                <CancelButton onCancel={onCancel} />
              </Space>
            </FormWrapper.Single>
            <FormWrapper.Double>
              <Tabs>
                <Tabs.TabPane
                  tab={t('pages.admin.taxonomies.retailers.tabs.sellers')}
                  key="sellers"
                >
                  <SellersList collection="sellers" />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t('pages.admin.taxonomies.retailers.tabs.pos')}
                  key="pos"
                >
                  <ReteailerPosesList />
                </Tabs.TabPane>
                {isStocksEnabled && (
                  <>
                    <Tabs.TabPane
                      tab={t(
                        'pages.admin.taxonomies.retailers.tabs.chiefCashiers',
                      )}
                      key="chiefCashiers"
                    >
                      <SellersList collection="headCashiers" />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={t('pages.admin.taxonomies.retailers.tabs.cashiers')}
                      key="cashiers"
                    >
                      <SellersList collection="cashiers" />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={t('pages.admin.taxonomies.retailers.tabs.tvm')}
                      key="tvm"
                    >
                      <RetailerTvmList />
                    </Tabs.TabPane>
                  </>
                )}
              </Tabs>
            </FormWrapper.Double>
          </FormWrapper>
        </form>
      </FormContext.Provider>
    </>
  );
};

export default EditRetailerForm;
