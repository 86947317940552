import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Collapse,
  notification,
  Popconfirm,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { FileSyncOutlined, SaveOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useLocation } from 'react-router-dom';
import useForm from '../../hooks/useForm';
import { getRolesWithDetails } from '../../services/admin/roles';
import useDictionaries from '../../hooks/useDictionaries';
import FormWrapper from '../FormWrapper';
import { getUserTypes } from '../../services/taxonomies/user-types';
import SaveButton from '../buttons/SaveButton';
import CancelButton from '../buttons/CancelButton';
import UserFileList from './UserFileList';
import { CustomerTypes } from './constants';
import ScanCardDetailsButton from '../buttons/ScanCardDetailsButton';
import { getOperatorCardDetailsByUserId } from '../../services/accounts/accounts';
import { useRouteParams } from '../../hooks/useRouteParams';
import { getSubdivisions } from '../../services/taxonomies/subdivisions';
import { getInstitutions } from '../../services/taxonomies/institutions';
import { getCountries } from '../../services/taxonomies/countires';
import UserIdentifier from './UserIdentifier';
import AuthContext, { hasPermission } from '../auth';

const dictionaries = {
  roles: getRolesWithDetails,
  userTypes: getUserTypes,
  countries: () =>
    getCountries({ pageSize: 200, criterias: { deleted: 'false' } }),
};

const populateInstitutions = (userTypeId, userTypes, setInstitutions) => {
  const insts =
    userTypes.find((ut) => ut.id === userTypeId)?.institutions || [];
  if (insts.length) {
    getInstitutions({ pageSize: 1000, criterias: { ids: insts } }).then(
      (res) => {
        setInstitutions(res.content);
      },
    );
  } else {
    setInstitutions([]);
  }
};

const populateSubdivisions = (institutionId, institutions, setSubdivisions) => {
  const subdivisions =
    institutions.find((i) => i.id === institutionId)?.subdivisions || [];
  if (subdivisions.length) {
    getSubdivisions({ pageSize: 1000, criterias: { ids: subdivisions } }).then(
      (res) => {
        setSubdivisions(res.content);
      },
    );
  } else {
    setSubdivisions([]);
  }
};

const EditUserForm = ({ user, onSubmit, onCancel, onDeletePersonalData }) => {
  const { t } = useTranslation();

  const { isNew } = useRouteParams();

  const [wasNotVerified, setWasNotVerified] = useState(true);

  const [institutions, setInstitutions] = useState([]);

  const [subdivisions, setSubdivisions] = useState([]);

  const [activeKeys, setActiveKey] = useState(['address2']);

  const { user: currentUser } = useContext(AuthContext);

  const schema = useMemo(
    () =>
      yup
        .object()
        .shape({
          email: yup.string().nullable().email(t('errors.incorrectField')),
          files: yup.array().of(
            yup.object().shape({
              docTypeId: yup.number().required(t('errors.mustSelected')),
            }),
          ),
          fullname: yup.string().required(t('errors.notEmpty')),
          customerType: yup.string().required(t('errors.mustSelected')),
          identityCard: yup
            .string()
            .required(t('errors.notEmpty'))
            .max(40, t('errors.maxLength', { max: 40 })),
          userTypeId: yup.number().required(t('errors.mustSelected')),
          status: yup.string().required(t('errors.mustSelected')),
          roleId: yup.number().required(t('errors.mustSelected')),
          // employeeNumber: yup
          //   .string()
          //   .matches(/^(\d+)?$/, t('errors.incorrectField')),
          phone: yup
            .string()
            .nullable()
            .matches(/^(\+?\d+)?$/, t('errors.incorrectField')),
          pin: yup
            .string()
            .nullable()
            .matches(/^(\d{4})?$/, t('errors.incorrectField')),
        })
        .when((obj, yupSchema) => {
          if (wasNotVerified) {
            yupSchema.validate(obj).catch(() => {
              notification.error({
                message: t('errors.incorrectFields'),
              });
            });
            setWasNotVerified(false);
          }
          return yupSchema;
        }),
    [t, wasNotVerified],
  );

  const form = useForm({
    initialValue: user,
    schema,
    onSubmit: (usr) => {
      setWasNotVerified(true);
      return onSubmit(usr);
    },
    onCancel,
  });

  const {
    handleSubmit,
    input,
    select,
    custom,
    value,
    setFormValue,
    setValue,
    checkbox,
  } = form;

  const [{ roles, userTypes, countries }] = useDictionaries(dictionaries);

  useEffect(() => {
    populateInstitutions(value.userTypeId, userTypes.content, setInstitutions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTypes.content, value.userTypeId]);

  useEffect(() => {
    populateSubdivisions(value.institutionId, institutions, setSubdivisions);
  }, [
    institutions,
    setFormValue,
    userTypes.content,
    value.institutionId,
    value.userTypeId,
  ]);

  const operatorRoleIds = useMemo(
    () =>
      roles.content
        .filter((r) => r.permissions.some((p) => p.name === 'OPERATOR'))
        .map((r) => r.id),
    [roles.content],
  );

  const rolesList = useMemo(
    () =>
      isNew
        ? roles.content.filter((r) => !operatorRoleIds.includes(r.id))
        : roles.content,
    [isNew, operatorRoleIds, roles.content],
  );

  const countryOptions = useMemo(
    () =>
      (countries?.content || []).map((ety) => ({
        ...ety,
        label: ety.name,
        value: ety.id,
        disabled: ety.deleted,
      })),
    [countries],
  );

  const countryId = useMemo(
    () => value?.address2?.countryId || -1,
    [value?.address2?.countryId],
  );

  const districtOptions = useMemo(
    () =>
      (countries?.content || []).find((c) => c.id === countryId)?.districts ||
      [],
    [countries?.content, countryId],
  );

  const handleChangeUserTypeId = useCallback(
    (v) => {
      if (v) {
        setSubdivisions([]);
      }

      setFormValue({
        ...value,
        userTypeId: v,
        institutionId: null,
        subdivisionId: null,
      });
    },
    [setFormValue, value],
  );

  const handleChangeInstitution = useCallback(() => {
    setFormValue({ ...value, subdivisionId: null });
    setSubdivisions([]);
  }, [setFormValue, value]);

  const UserStatus = useMemo(
    () => [
      {
        id: 'ACTIVE',
        name: t('entity.admin.user.status.ACTIVE'),
      },
      {
        id: 'DELETED',
        name: t('entity.admin.user.status.DELETED'),
      },
    ],
    [t],
  );

  const [cardDetails, setCardDetails] = useState(null);

  const isOperator = useMemo(
    () => !isNew && value.roleId && operatorRoleIds.includes(value.roleId),
    [isNew, operatorRoleIds, value.roleId],
  );

  useEffect(() => {
    if (isOperator && cardDetails && cardDetails.userId === value.id) {
      setValue({
        cardLogicIdSerial: cardDetails.logicIdSerial,
        cardPhysicalId: cardDetails.physicalId,
      });
    } else {
      setValue({ cardLogicIdSerial: null, cardPhysicalId: null });
    }
  }, [cardDetails, isOperator, setValue, value.id]);

  useEffect(() => {
    if (!isNew && isOperator) {
      getOperatorCardDetailsByUserId(value.id).then((crd) =>
        setValue({
          cardLogicIdSerial: crd.logicIdSerial,
          cardPhysicalId: crd.physicalId,
        }),
      );
    }
  }, [isNew, isOperator, setValue, value.id, user]);

  const location = useLocation();
  const { onlyView: stateOnlyView } = location.state || false;

  const canOnlyEdit = useMemo(() => {
    return hasPermission(currentUser.permissions, [
      'ADMINISTRATION_USERS_EDIT_PERMISSION',
    ]);
  }, [currentUser.permissions]);

  const canOnlyView = useMemo(() => {
    return canOnlyEdit
      ? stateOnlyView
      : hasPermission(currentUser.permissions, [
          'ADMINISTRATION_USERS_VIEW_PERMISSION',
        ]) || stateOnlyView;
  }, [stateOnlyView, canOnlyEdit, currentUser.permissions]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Select
              label={t('entity.admin.user.customerType._')}
              props={{
                options: CustomerTypes.content,
                disabled: value.id || canOnlyView,
                ...select('customerType'),
                labelProp: (option) =>
                  t(`entity.admin.user.customerType.${option.id}`),
              }}
            />

            <FormWrapper.Input
              label={t('entity.admin.user.email')}
              props={{
                ...input('email'),
                autocomplete: 'off',
                disabled: canOnlyView,
              }}
            />

            <UserIdentifier
              form={form}
              countries={countries}
              disabled={canOnlyView}
            />

            <FormWrapper.Input
              label={t('entity.admin.user.fullname')}
              props={{
                ...input('fullname'),
                onChange: (e) => {
                  const cursorPosition = e.target.selectionStart;
                  const newText =
                    e.target.value.substring(0, cursorPosition - 1) +
                    (cursorPosition > 0
                      ? e.target.value[cursorPosition - 1].toUpperCase()
                      : '') +
                    e.target.value.substring(cursorPosition);
                  setValue('fullname', newText.toUpperCase());

                  requestAnimationFrame(() => {
                    e.target.setSelectionRange(cursorPosition, cursorPosition);
                  });
                },
                disabled: canOnlyView,
              }}
            />

            <FormWrapper.Input
              label={t('entity.admin.user.phone')}
              props={{
                ...input('phone'),
                disabled: canOnlyView,
              }}
            />

            <Typography.Paragraph style={{ marginBottom: 0 }}>
              {t('entity.admin.user.address._')}
            </Typography.Paragraph>
            <Collapse
              activeKey={activeKeys}
              onChange={() => setActiveKey(activeKeys ? null : 'address2')}
            >
              <CollapsePanel key="address2">
                <FormWrapper.Input
                  label={t('entity.admin.user.address._')}
                  props={{ ...input('address'), disabled: true }}
                />
                <FormWrapper.Select
                  label={t('entity.admin.user.address.country')}
                  props={{
                    options: countryOptions,
                    loading: countryOptions.loading,
                    allowClear: true,
                    ...select('address2.countryId'),
                    onChange: (v1) => {
                      setValue('address2.countryId', v1);
                      setValue('address2.districtId', null);
                    },
                    disabled: canOnlyView,
                  }}
                />
                <FormWrapper.Select
                  label={t('entity.admin.user.address.district')}
                  props={{
                    options: districtOptions,
                    loading: false,
                    allowClear: true,
                    ...select('address2.districtId'),
                    disabled: canOnlyView,
                  }}
                />
                <FormWrapper.Input
                  label={t('entity.admin.user.address.locality')}
                  props={{
                    allowClear: true,
                    maxLength: 32,
                    ...input('address2.locality'),
                    disabled: canOnlyView,
                  }}
                />
                <FormWrapper.Input
                  label={t('entity.admin.user.address.street')}
                  props={{
                    allowClear: true,
                    maxLength: 32,
                    ...input('address2.street'),
                    disabled: canOnlyView,
                  }}
                />
                <FormWrapper.Input
                  label={t('entity.admin.user.address.number')}
                  props={{
                    allowClear: true,
                    maxLength: 8,
                    ...input('address2.number'),
                    disabled: canOnlyView,
                  }}
                />
                <FormWrapper.Input
                  label={t('entity.admin.user.address.building')}
                  props={{
                    allowClear: true,
                    maxLength: 8,
                    ...input('address2.building'),
                    disabled: canOnlyView,
                  }}
                />
                <FormWrapper.Input
                  label={t('entity.admin.user.address.apartment')}
                  props={{
                    allowClear: true,
                    maxLength: 8,
                    ...input('address2.apartment'),
                    disabled: canOnlyView,
                  }}
                />
                <FormWrapper.Input
                  label={t('entity.admin.user.address.details')}
                  props={{
                    allowClear: true,
                    maxLength: 64,
                    ...input('address2.details'),
                    disabled: canOnlyView,
                  }}
                />
              </CollapsePanel>
            </Collapse>

            <FormWrapper.Select
              label={t('entity.admin.user.status._')}
              props={{
                options: UserStatus,
                ...select('status'),
                disabled: canOnlyView,
              }}
            />

            <FormWrapper.Select
              label={t('entity.admin.user.profile')}
              props={{
                ...select('profileId'),
                disabled: true,
              }}
            />

            <FormWrapper.Select
              label={t('entity.admin.user.role')}
              props={{
                options: rolesList,
                loading: roles.loading,
                allowClear: true,
                ...select('roleId'),
                disabled: canOnlyView,
              }}
            />

            {isOperator && (
              <>
                <FormWrapper.FieldWrapper
                  errorMessage={
                    cardDetails && cardDetails.userId !== value.id
                      ? t('errors.cardNotBelongToUser')
                      : null
                  }
                >
                  <ScanCardDetailsButton setCardDetails={setCardDetails} />
                </FormWrapper.FieldWrapper>

                <FormWrapper.Input
                  label={t('entity.admin.user.cardLogicIdSerial')}
                  props={{
                    disabled: true,
                    ...input('cardLogicIdSerial'),
                  }}
                />
              </>
            )}

            <FormWrapper.Select
              label={t('entity.admin.user.userType')}
              props={{
                options: userTypes.content,
                loading: userTypes.loading,
                ...select('userTypeId'),
                onChange: handleChangeUserTypeId,
                disabled: canOnlyView,
              }}
            />
            <FormWrapper.Select
              label={t('entity.admin.user.institutionId')}
              props={{
                showSearch: true,
                allowClear: true,
                options: institutions,
                ...select('institutionId'),
                onChange: (institutionId) => {
                  handleChangeInstitution(institutionId);
                  setValue('institutionId', institutionId);
                },
                filterOption: (input2, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input2.toLowerCase()) !== -1,
                disabled: canOnlyView,
              }}
            />
            <FormWrapper.Select
              label={t('entity.admin.user.subdivisionId')}
              props={{
                showSearch: true,
                allowClear: true,
                options: subdivisions,
                ...select('subdivisionId'),
                filterOption: (input2, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input2.toLowerCase()) !== -1,
                disabled: canOnlyView,
              }}
            />

            {((!isNew && isOperator) || value.employeeNumber) && (
              <FormWrapper.Input
                label={t('entity.admin.user.employeeNumber')}
                props={{
                  allowClear: true,
                  ...input('employeeNumber'),
                  disabled: canOnlyView,
                }}
              />
            )}
            <FormWrapper.Input
              label={t('entity.admin.user.metaData1')}
              props={{
                allowClear: true,
                ...input('metaData1'),
                disabled: canOnlyView,
              }}
            />
            <FormWrapper.Input
              label={t('entity.admin.user.metaData2')}
              props={{
                allowClear: true,
                ...input('metaData2'),
                disabled: canOnlyView,
              }}
            />
            <FormWrapper.Password
              label={t('entity.admin.user.pin')}
              props={{
                allowClear: true,
                ...input('pin'),
                autocomplete: 'new-password',
                disabled: canOnlyView,
              }}
            />
            {form.value.customerType === 'LEGAL' && (
              <FormWrapper.Switch
                label={t('entity.admin.user.hasContract')}
                props={{
                  ...checkbox('hasContract'),
                  disabled: canOnlyView,
                }}
              />
            )}
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Tabs>
              <Tabs.TabPane
                tab={t('entity.admin.user.tabs.files._')}
                key="files"
              >
                <UserFileList
                  props={{
                    ...custom('files'),
                    select,
                    custom,
                    disabled: canOnlyView,
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
          </FormWrapper.Double>
        </FormWrapper>

        <Space>
          {!canOnlyView && (
            <>
              {!user.id && <SaveButton />}{' '}
              {user.id && user.pin !== value.pin && (
                <Popconfirm
                  placement="top"
                  title={t('entity.admin.user.changePin')}
                  disabled={false}
                  onConfirm={() => handleSubmit(value)}
                  okText={t('actions.save')}
                  cancelText={t('actions.cancel')}
                >
                  <Tooltip title={t('actions.save')}>
                    <Button type="primary" icon={<SaveOutlined />}>
                      {t('actions.save')}
                    </Button>
                  </Tooltip>
                </Popconfirm>
              )}
              {user.id && user.pin === value.pin && <SaveButton />}{' '}
            </>
          )}

          <CancelButton onCancel={onCancel} />
        </Space>
        {!canOnlyView ? (
          <div align="right">
            <Popconfirm
              placement="top"
              title={t('entity.admin.user.deletePersonalData')}
              disabled={false}
              onConfirm={() => onDeletePersonalData(value.id)}
              okText={t('actions.delete')}
              cancelText={t('actions.cancel')}
            >
              <Tooltip title={t('actions.delete')}>
                <Button
                  icon={<FileSyncOutlined />}
                  style={{ background: 'red' }}
                >
                  {t('actions.deletePersonalData')}
                </Button>
              </Tooltip>
            </Popconfirm>
          </div>
        ) : undefined}
      </form>
    </>
  );
};

export default EditUserForm;
