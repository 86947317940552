import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Table } from 'antd';

import { Default } from '../../../layouts';
import useDatasource from '../../../hooks/useDatasource';
import { getUsers, deleteUser } from '../../../services/admin/users';
import PageTitle from '../../../components/PageTitle';
import AddEntityButton from '../../../components/buttons/AddEntityButton';
import EditItemIcon from '../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../components/buttons/DeleteItemIcon';
import Column from '../../../helpers/columns';
import AuthContext, { hasPermission } from '../../../components/auth';
import ViewItemIcon from '../../../components/buttons/ViewItemIcon';

const BADGE_COLORS = {
  ACTIVE: 'green',
  DELETED: '#999',
};

const CUSTOMER_TYPES = {
  content: [{ id: 'LEGAL' }, { id: 'INDIVIDUAL' }],
  loading: false,
  error: false,
};

const permissionsMap = {
  canView: [
    'ADMINISTRATION_USERS_VIEW_PERMISSION',
    'ADMINISTRATION_USERS_PERMISSION',
    'SYS_SUPERUSER',
  ],
  canEdit: [
    'ADMINISTRATION_USERS_EDIT_PERMISSION',
    'ADMINISTRATION_USERS_PERMISSION',
    'SYS_SUPERUSER',
  ],
  canDelete: [
    'ADMINISTRATION_USERS_DELETE_PERMISSION',
    'ADMINISTRATION_USERS_PERMISSION',
    'SYS_SUPERUSER',
  ],
};

const UsersList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const { pagination, content, handleChange, reload } = useDatasource(getUsers);

  const userPermissions = useMemo(() => {
    return Object.fromEntries(
      Object.entries(permissionsMap).map(([key, values]) => [
        key,
        Array.isArray(values)
          ? hasPermission(permissions, values)
          : hasPermission(permissions, [values]),
      ]),
    );
  }, [permissions]);

  const { canView, canEdit, canDelete } = userPermissions;

  const actionsColumn = useMemo(
    () =>
      Column.actions(t('table.actions'), (record) => {
        const hasActions = canView || canEdit || canDelete;

        return (
          <span style={{ textAlign: 'right' }}>
            {canView && <ViewItemIcon path={`/admin/users/${record.id}`} />}
            {canEdit && <EditItemIcon path={`/admin/users/${record.id}`} />}
            {canDelete && (
              <DeleteItemIcon
                title={t('entity.admin.user._delete', record)}
                message={t('entity.admin.user._deleted', record)}
                item={record}
                action={deleteUser}
                reload={reload}
              />
            )}
            {!hasActions && {}}
          </span>
        );
      }),
    [canView, canEdit, canDelete, t, reload],
  );

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.admin.user.id'), {
        width: 75,
        filter: true,
      }),
      Column.text('email', t('entity.admin.user.email'), {
        width: 300,
        filter: true,
      }),
      Column.text('fullname', t('entity.admin.user.fullname'), {
        sort: false,
        width: 300,
        filter: true,
      }),
      Column.text('identityCard', t('entity.admin.user.identityCard'), {
        sort: false,
        width: 300,
        filter: true,
      }),
      Column.text('role', t('entity.admin.user.role'), {
        sort: false,
        width: 200,
        filter: true,
      }),
      Column.date('createdAt', t('entity.admin.user.createdAt'), {
        width: 250,
      }),
      Column.text('createdBy', t('entity.admin.user.createdBy'), {
        width: 150,
      }),
      Column.dictionary(
        'customerType',
        t('entity.admin.user.customerType._'),
        CUSTOMER_TYPES,
        {
          filter: true,
          width: 150,
          dictLabel: (entry) => t(`entity.admin.user.customerType.${entry.id}`),
        },
      ),
      {
        title: t('entity.admin.user.status._'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        width: 150,
        render: (value) => (
          <Badge
            color={BADGE_COLORS[value] || '#fff'}
            text={t(`entity.admin.user.status.${value}`)}
          />
        ),
      },
      actionsColumn,
    ],
    [t, actionsColumn],
  );

  return (
    <Default>
      {hasPermission(permissions, 'SYS_SUPERUSER') && (
        <PageTitle title={t('entity.admin.user._plural')}>
          <AddEntityButton
            entity={t('entity.admin.user._singular')}
            path="/admin/users/new"
          />
        </PageTitle>
      )}
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default UsersList;
