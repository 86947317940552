import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Space, Table } from 'antd';

import useDatasource from '../../hooks/useDatasource';
import Column from '../../helpers/columns';

import { getSaleReport, downloadAll } from '../../services/mobile/mobile';
import PageTitle from '../PageTitle';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';

const SaleReportGridMobile = () => {
  const { t } = useTranslation();

  const { pagination, sort, criterias, content, handleChange } =
    useDatasource(getSaleReport);

  useEffect(() => {
    if (content?.[0]) {
      localStorage.setItem('julianStartDate', content[0].julianStartDate);
    }
  }, [content]);

  const columns = useMemo(
    () => [
      Column.text('orderId', t('components.salesReport.orderId'), {
        filter: true,
      }),
      Column.text('origProductId', t('components.salesReport.origProductId'), {
        filter: true,
        width: 125,
      }),
      Column.text(
        'origProductName',
        t('components.salesReport.origProductName'),
        { filter: true },
      ),
      Column.text('physicalId', t('components.salesReport.physicalId'), {
        filter: true,
        width: 300,
      }),
      {
        title: t('components.salesReport.price'),
        width: 150,
        key: 'price',
        dataIndex: 'price',
        render: (price) => <>{(price * 0.01).toFixed(2)}</>,
        sorter: true,
      },
      Column.text('qty', t('components.salesReport.qty'), { filter: true }),
      Column.date('updatedAt', t('components.salesReport.updatedAt'), {
        filter: true,
      }),
      Column.text('userFullname', t('components.salesReport.userFullname'), {
        filter: true,
      }),
      Column.text(
        'userIdentityCard',
        t('components.salesReport.userIdentityCard'),
        { filter: true },
      ),
      Column.date('fromDate', t('components.salesReport.fromDate'), {
        sort: false,
      }),
      Column.text('dateStatusName', t('components.salesReport.statusDate'), {
        width: 150,
        filter: true,
      }),
      Column.date(
        'startValidPeriod',
        t('components.salesReport.startValidPeriod'),
        {
          sort: false,
        },
      ),
      Column.date(
        'endValidPeriod',
        t('components.salesReport.endValidPeriod'),
        {
          sort: false,
        },
      ),
    ],
    [t],
  );

  return (
    <>
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAll}
          />
        </Space>
      </PageTitle>
      <Table
        rowKey="productId"
        columns={columns}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
        size="small"
      />
    </>
  );
};

export default SaleReportGridMobile;
